<template>
  <div class="mortgage">
    <van-nav-bar
      left-arrow
      fixed
      safe-area-inset-top
      placeholder
      title="存料"
      @click-left="leftClick"
    />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="box">
        <div class="flex_between  border">
          <div class="explain">
            {{
              goodsType === "baiyin"
                ? "白银"
                : goodsType === "bojin"
                ? "铂金"
                : goodsType === "bajin"
                ? "钯金"
                : "黄金"
            }}现价(元/克)
          </div>
          <div
            class="number"
            :style="{
              color:
                goodsType === 'baiyin'
                  ? agRedColor
                  : goodsType === 'bojin'
                  ? ptRedColor
                  : goodsType === 'bajin'
                  ? pdRedColor
                  : redColor
                  ? '#ff2d2e'
                  : '#02790D'
            }"
          >
            {{
              goodsType === "baiyin"
                ? pricebaiy
                : goodsType === "bojin"
                ? priceboj
                : goodsType === "bajin"
                ? pricebaj
                : pricehj
            }}
          </div>
        </div>
        <div class="flex_between marginTop">
          <div class="explain ">存料单数量（单）/存料单总重量（克）</div>
          <div class="number">
            {{ checkList.length }}/{{ totalData.orderWeight }}
          </div>
        </div>
        <div class="flex_between">
          <div class="explain">存料初始均价（元/克）</div>
          <div class="number">{{ totalData.averagePrice }}</div>
        </div>
        <div class="flex_between">
          <div class="explain">已收总金额（元）</div>
          <div class="number">{{ totalData.preMoney }}</div>
        </div>
        <div class="flex_between">
          <div class="explain fontSize">总其他费用（元）</div>
          <div class="number fontSize">{{ totalData.CustodyFee }}</div>
        </div>
        <div class="flex_between">
          <div class="explain  fontSize">总补仓金额(元)</div>
          <div class="number fontSize">{{ totalData.addMoney }}</div>
        </div>
        <div class="flex_between">
          <div class="explain fontSize">总结算尾款（元）</div>
          <div class="number fontSize">{{ totalData.statementMoney }}</div>
        </div>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="flex" v-for="(item, index) in getMortgageList" :key="index">
          <div class="line"></div>
          <div class="orderInfo">
            <div class="flex_between borderBtm">
              <div class="flex">
                <h2>黄金金条9999</h2>
                <div class="icon">
                  存料
                </div>
              </div>
              <div class="flex">
                <div class="time">{{ item.time | formatDate_ }}</div>
                <input
                  type="checkbox"
                  class="checkBox"
                  name="checkBoxTest"
                  :value="item"
                  v-model="checkList"
                  @change="checkBox"
                />
              </div>
            </div>
            <div class="flex_between marginTop">
              <div class="explain">存料重量(克)/初始单价(元/克)</div>
              <div class="number">{{ item.weight }}/{{ item.initPrice }}</div>
            </div>

            <div class="flex_between marginTop">
              <div class="explain">
                累计仓租 (自{{ item.time | formatDate_ | formatTimeYear }}起)
              </div>
              <div class="number">{{ item.warehouseMoney }}</div>
            </div>
            <div class="flex_between marginTop">
              <div class="explain">快递费（元）</div>
              <div class="number">{{ item.moneyExpress }}</div>
            </div>
            <div class="flex_between marginTop">
              <div class="explain">保险费（元）</div>
              <div class="number">{{ item.moneyInsurance }}</div>
            </div>
            <div class="flex_between marginTop">
              <div class="explain">已收金额（元）</div>
              <div class="number">{{ item.money }}</div>
            </div>
            <div class="flex_between marginTop">
              <div class="explain">补仓金额（元）</div>
              <div class="number">{{ item.makeUpMoney }}</div>
            </div>
            <div class="flex_between marginTop">
              <div class="explain">剩余比例(%)</div>
              <div class="number">{{ item.currentDepositPercent }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <!-- 违约结算弹窗 -->
    <van-dialog
      v-model="showbreak"
      :show-confirm-button="false"
      get-container="#app"
    >
      <div class="title">存料结算</div>
      <div class="centerbox_dialog">
        <div class="flex_between col999">
          <div>平仓价格 (元)</div>
          <div>{{ pricehj }}</div>
        </div>
        <div class="flex_between col999">
          <div>平仓克重 (克)</div>
          <div>{{ totalData.orderWeight }}</div>
        </div>
      </div>
      <div class="flex_between popbtn">
        <div class="gocer closebtn" @click="close">取消</div>
        <div class="gocer confirmbtn" @click="commit">确定</div>
      </div>
    </van-dialog>
    <van-dialog
      v-model="ceringModel"
      class="ceringModel"
      :show-confirm-button="false"
      get-container="#app"
    >
      <div class="title">提示</div>
      <div class="centerbox_dialog">{{ stopTrading }}</div>
      <div class="confirm" @click="ceringModel = false">我知道了</div>
    </van-dialog>
    <div @click="breakjieSuan" class="boxBtn">
      <div class="btn">存料结算</div>
    </div>
  </div>
</template>

<script>
import * as api from "@/utils/api";
import Stomp from "stompjs";

export default {
  data() {
    return {
      showbreak: false, // 结算弹框是否显示
      ceringModel: false, // 停盘弹框是否显示
      redColor: true, // 判断黄金现价涨还是跌 涨红色 跌绿色
      agRedColor: true, // 判断白银现价涨还是跌 涨红色 跌绿色
      pdRedColor: true, // 判断钯金现价涨还是跌 涨红色 跌绿色
      ptRedColor: true, // 判断铂金现价涨还是跌 涨红色 跌绿色
      getMortgageList: [], // 未平仓存料订单
      checkList: [], // 复选框选中数据
      totalData: {
        orderWeight: 0, // 勾选的存料总重量
        averagePrice: 0, // 勾选的存料初始均价
        preMoney: 0, // 勾选的存料已收金额
        CustodyFee: 0, // 勾选的存料其他费用
        addMoney: 0, // 勾选的存料补仓金额
        statementMoney: 0 // 勾选的存料结算尾款
      },
      client: {},
      // 所有商品数据
      allpro: [],
      // 首次加载的数据
      initDatalist: [],
      // 是否开盘
      trade_: false,
      // 提交时间
      postTime: "",
      firstloadData: true,
      pricehj: 0,
      priceboj: 0,
      pricebaj: 0,
      pricebaiy: 0,
      initCity: {
        huangjin: "huangjin9999",
        baiyin: "baiyin9999",
        bajin: "bajin9996",
        bojin: "bojin9996"
      },
      refreshing: false,
      loading: false,
      finished: false,
      page: 0,
      size: 8,
      stopTrading: "", //停盘说明
      miniOrderTime: [], // 小克重下单时间
      stopMiniTrading: "", // 小克重停盘说明
      isClick: false, // 结算多次点击判断
      goodsType: "huangjin" // 暂时都是黄金
    };
  },
  mounted() {
    this.connect();
    this.DefaultExplain();
  },
  watch: {
    allpro: function() {
      if (this.initCity != "") {
        this.updatearr(this.initCity);
        this.ProcessingData(this.getMortgageList);
        let weight = 0;
        let junjia = 0;
        let preMoney = 0;
        let custodyFee = 0;
        let addMoney = 0;
        let statementMoney = 0;
        this.checkList.forEach(item => {
          //  订单重量
          weight += Number(item.weight);
          //  订单均价
          junjia += +item.initPrice * item.weight;
          //物料差价
          preMoney += +item.money;
          // 保管费
          custodyFee +=
            -Number(item.moneyExpress) -
            Number(item.moneyInsurance) -
            Number(item.warehouseMoney);
          // 总定金
          addMoney += +item.makeUpMoney;
          // 订单占用押金
          statementMoney += Number(
            (
              Number(item.mortgageFreeWeight) * this.pricehj +
              Number(item.makeUpMoney) -
              Number(item.moneyExpress) -
              Number(item.moneyInsurance) -
              Number(item.money) -
              Number(item.warehouseMoney)
            ).toFixed(2)
          );
          this.totalData.orderWeight = weight;
          this.totalData.averagePrice = (junjia / weight).toFixed(2);
          this.totalData.preMoney = preMoney;
          this.totalData.addMoney = addMoney;
          this.totalData.custodyFee = custodyFee;
          this.totalData.statementMoney = statementMoney;
        });
      }
    },
    checkList: function() {
      if (this.checkList.length == 0) {
        this.totalData.orderWeight = 0;
        this.totalData.averagePrice = 0;
        this.totalData.preMoney = 0;
        this.totalData.CustodyFee = 0;
        this.totalData.addMoney = 0;
        this.totalData.occupyDepstatementMoneyosi = 0;
      }
    },
    pricehj(newVal, oldVal) {
      oldVal > newVal ? (this.redColor = false) : (this.redColor = true);
    },
    priceag(newVal, oldVal) {
      oldVal > newVal ? (this.agRedColor = false) : (this.agRedColor = true);
    },
    pricepd(newVal, oldVal) {
      oldVal > newVal ? (this.pdRedColor = false) : (this.pdRedColor = true);
    },
    pricept(newVal, oldVal) {
      oldVal > newVal ? (this.ptRedColor = false) : (this.ptRedColor = true);
    }
  },
  methods: {
    leftClick() {
      this.$router.push({
        name: "keepRecord"
      });
    },
    // 违约说明
    async DefaultExplain() {
      const stopTrading = await api.getDefaultExplain({ title: "" });
      stopTrading.forEach(item => {
        if (item.title === "stopTradingSamllDescription") {
          this.stopTrading = item.content;
        }
      });
      api.getMiniOrderTime().then(res => {
        this.miniOrderTime = res.orderTime;
      });
    },
    async onLoad() {
      const res = await api.getMortgageingData({
        page: this.page,
        size: this.size
      });
      this.page = res.number + 1;
      this.getMortgageList.push(...res.content);
      this.loading = false;
      if (res.last == true) {
        this.finished = true;
        this.ProcessingData(this.getMortgageList);
      }
    },
    async onRefresh() {
      this.finished = false;
      const res = await api.getMortgageingData({ page: 0, size: this.size });
      this.page = res.number + 1;
      this.getMortgageList = res.content;
      this.refreshing = false;
      this.loading = false;
      if (res.last == true) {
        this.finished = true;
        this.ProcessingData(this.getMortgageList);
      }
    },
    // 取消按钮事件
    close() {
      this.showbreak = false;
    },
    // 确认按钮点击事件
    async commit() {
      if (this.isClick) {
        setTimeout(() => {
          this.isClick = false;
        }, 1000);
        return false;
      }
      this.isClick = true;
      const orderList = [];
      this.checkList.forEach(item => {
        orderList.push({
          openId: item.openId,
          time: this.postTime,
          weight: item.mortgageFreeWeight
        });
      });
      this.showbreak = false;
      const res = await api.confirmMortgageStatement({ orderList: orderList });
      this.$router.push({
        name: "submitStockExplain"
      });
    },
    timeSwitch(date1) {
      const nowTime1 = new Date();
      nowTime1.setHours(date1.getHours());
      nowTime1.setMinutes(date1.getMinutes());
      nowTime1.setSeconds(date1.getSeconds());
      return nowTime1;
    },
    // 复选框选中事件
    checkBox(e) {
      if (event.target.checked) {
        const nowTime = new Date();
        const date1 = new Date(this.miniOrderTime[0].start * 1000);
        const date2 = new Date(this.miniOrderTime[0].end * 1000);
        const date3 = new Date(this.miniOrderTime[1].start * 1000);
        const date4 = new Date(this.miniOrderTime[1].end * 1000);
        const nowTime1 = this.timeSwitch(date1);
        const nowTime2 = this.timeSwitch(date2);
        const nowTime3 = this.timeSwitch(date3);
        const nowTime4 = this.timeSwitch(date4);
        if (
          !(
            (nowTime.getTime() - nowTime1.getTime() > 0 &&
              nowTime.getTime() - nowTime2.getTime() < 0) ||
            (nowTime.getTime() - nowTime3.getTime() > 0 &&
              nowTime.getTime() - nowTime4.getTime() < 0)
          )
        ) {
          this.checkList = this.checkList.filter(function(item, index) {
            return item.orderId !== event.target._value.orderId;
          });
          event.target.checked = false;
          return this.$toast(this.stopTrading), false;
        }
      }
      let weight = 0;
      let junjia = 0;
      let preMoney = 0;
      let custodyFee = 0;
      let addMoney = 0;
      let statementMoney = 0;
      this.checkList.forEach(item => {
        //  订单重量
        weight += Number(item.weight);
        //  订单均价
        junjia += +item.initPrice * item.weight;
        //物料差价
        preMoney += +item.money;
        // 保管费
        custodyFee +=
          -Number(item.moneyExpress) -
          Number(item.moneyInsurance) -
          Number(item.warehouseMoney);
        // 总定金
        addMoney += +item.makeUpMoney;
        // 订单占用押金
        statementMoney += Number(
          (
            Number(item.mortgageFreeWeight) * this.pricehj +
            Number(item.makeUpMoney) -
            Number(item.moneyExpress) -
            Number(item.moneyInsurance) -
            Number(item.money) -
            Number(item.warehouseMoney)
          ).toFixed(2)
        );
        this.totalData.orderWeight = weight;
        this.totalData.averagePrice = (junjia / weight).toFixed(2);
        this.totalData.preMoney = preMoney;
        this.totalData.addMoney = addMoney;
        this.totalData.custodyFee = custodyFee;
        this.totalData.statementMoney = statementMoney;
      });
    },
    // 数据处理
    ProcessingData(ViolateList) {
      ViolateList.forEach((item, index) => {
        const depositProportion = (
          ((Number(item.mortgageFreeWeight) * this.pricehj +
            Number(item.makeUpMoney) -
            Number(item.money) -
            Number(item.moneyExpress) -
            Number(item.moneyInsurance)) *
            100) /
          (Number(item.initPrice) * Number(item.mortgageFreeWeight))
        ).toFixed(2);
        item.currentDepositPercent = depositProportion;
      });
    },
    // 违约结算按钮事件
    breakjieSuan() {
      const nowTime = new Date();
      const date1 = new Date(this.miniOrderTime[0].start * 1000);
      const date2 = new Date(this.miniOrderTime[0].end * 1000);
      const date3 = new Date(this.miniOrderTime[1].start * 1000);
      const date4 = new Date(this.miniOrderTime[1].end * 1000);
      const nowTime1 = this.timeSwitch(date1);
      const nowTime2 = this.timeSwitch(date2);
      const nowTime3 = this.timeSwitch(date3);
      const nowTime4 = this.timeSwitch(date4);
      for (const item of this.checkList) {
        if (
          !(
            (nowTime.getTime() - nowTime1.getTime() > 0 &&
              nowTime.getTime() - nowTime2.getTime() < 0) ||
            (nowTime.getTime() - nowTime3.getTime() > 0 &&
              nowTime.getTime() - nowTime4.getTime() < 0)
          )
        ) {
          this.$toast(this.stopTrading), false;
          this.checkList = [];
          return;
        }
      }
      if (!this.trade_) {
        this.ceringModel = true;
        return false;
      }
      if (this.checkList.length == 0) {
        this.$toast("请选择订单");
        return false;
      }
      this.showbreak = true;
    },
    unzip(b64Data) {
      let strData = atob(b64Data);
      const charData = strData.split("").map(function(x) {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      const data = pako.inflate(binData);
      strData = String.fromCharCode.apply(null, new Uint16Array(data));
      return decodeURIComponent(escape(strData));
    },
    onConnected() {
      const topic = "/price/all";
      this.client.subscribe(topic, this.responseCallback, this.onFailed);
    },
    connect() {
      this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      // ---初始化mqtt客户端，并连接mqtt服务
      const headers = {
        login: "username",
        passcode: "password"
      };
      this.client.connect(headers, this.onConnected, this.onFailed);
      this.client.debug = null;
    },
    onFailed(frame) {
      console.log("错误: " + frame);
      if (!this.client.connected) {
        setTimeout(() => {
          this.connect();
        }, 1000);
      }
    },
    deepMerge(...objs) {
      const than = this;
      const result = Object.create(null);
      objs.forEach(obj => {
        if (obj) {
          Object.keys(obj).forEach(key => {
            const val = obj[key];
            if (than.isPlainObject(val)) {
              // 递归
              if (than.isPlainObject(result[key])) {
                result[key] = than.deepMerge(result[key], val);
              } else {
                result[key] = than.deepMerge(val);
              }
            } else {
              //  数组也要重新赋值  不然依然会引用到其他的
              if (Array.isArray(val)) {
                result[key] = [...val];
              } else {
                result[key] = val;
              }
            }
          });
        }
      });
      return result;
    },
    isPlainObject(val) {
      const toString = Object.prototype.toString;
      return toString.call(val) === "[object Object]";
    },
    responseCallback(frame) {
      this.allpro = [];
      const resbody = eval("[" + this.unzip(frame.body) + "]")[0];
      const parse_res = JSON.parse(JSON.stringify(resbody));
      this.trade_ = resbody.trade;
      this.postTime = resbody.time;
      // console.log('postTime1',this.postTime);
      if (this.firstloadData) {
        this.initDatalist = resbody;
        this.firstloadData = false;
        for (const i in this.initDatalist) {
          if (this.initDatalist[i]["prices"] != undefined) {
            this.allpro.push(this.initDatalist[i]["prices"]);
          }
        }
      } else {
        this.initDatalist = this.deepMerge(this.initDatalist, parse_res);
        for (const i in this.initDatalist) {
          if (this.initDatalist[i]["prices"] != undefined) {
            this.allpro.push(this.initDatalist[i]["prices"]);
          }
        }
      }
    },
    // 价格变动更新视图
    pricenew(obj) {
      this.allpro.find(x => {
        if (x[obj.huangjin]) {
          this.pricehj = x[obj.huangjin]["huigou"];
        }
        if (x[obj.baiyin]) {
          this.pricebaiy = x[obj.baiyin]["huigou"];
        }
        if (x[obj.bajin]) {
          this.pricebaj = x[obj.bajin]["huigou"];
        }
        if (x[obj.bojin]) {
          this.priceboj = x[obj.bojin]["huigou"];
        }
      });
    },
    // 更新数组
    updatearr(obj) {
      this.pricenew(obj);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-list__finished-text {
    font-size: 24px;
  }
  .van-nav-bar {
    height: 90px;
    .van-nav-bar__content {
      line-height: 90px;
      height: 90px;
      .van-nav-bar__title {
        font-size: 36px;
      }
      .van-icon {
        font-size: 36px;
        color: #1e1e1e;
      }
    }
  }
}
.mortgage {
  background-color: #f5f5f5;
  padding: 10px 30px 100px;
  height: 100vh;
  .box {
    padding: 30px;
    background-color: #fff;
    border-radius: 8px 8px 8px 8px;
    margin-top: 30px;
    h3 {
      font-size: 30px;
      font-weight: 500;
    }
    .van-icon {
      color: #ff2d2e;
    }
  }
  .btn {
    width: 100%;
    height: 84px;
    background-color: #ff2d2e;
    color: #fff;
    text-align: center;
    line-height: 84px;
    font-size: 32px;
  }
}
.fontWeight {
  font-weight: 500;
}
.line {
  width: 8px;
  height: 570px;
  border-radius: 8px 0px 0px 8px;
  background-color: #cc0e0f;
  margin-top: 30px;
}
.orderInfo {
  width: 98%;
  padding: 10px 30px 0px;
  background-color: #fff;
  border-radius: 8px 8px 8px 8px;
  margin-top: 30px;
  h2 {
    font-size: 36px;
    font-weight: 400;
    color: #242424;
    line-height: 80px;
  }
  .time {
    line-height: 80px;
    color: #242424;
    font-size: 24px;
    margin-right: 30px;
  }
  .explain {
    color: #999999;
  }
}
.explain {
  letter-spacing: 2px;
  color: #666666;
  font-size: 28px;
}
.number {
  font-size: 28px;
}
.flex {
  display: flex;
  h2 {
    color: #242424;
  }
}
.flex_between {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}
.marginTop {
  margin-top: 4px;
}
.borderBtm {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 12px;
}
.icon {
  width: 60px;
  height: 32px;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 32px;
  border-radius: 4px 4px 4px 4px;
  background: #cc0e0f;
}
.bg {
  width: 100%;
  height: 100%;
}
.van-icon {
  top: 2px;
  color: #999999;
}
input[type="checkbox"] {
  width: 22px;
  height: 22px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  position: relative;
  margin-right: 20px;
  top: 26px;
}
input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  background: #fff;
  width: 36px;
  height: 36px;
  border: 1px solid #e9e9e9;
  background-color: #f5f5f5;
  border-radius: 50%;
}

input[type="checkbox"]:checked::before {
  content: "\2713";
  position: absolute;
  top: -6px;
  left: -6px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #fff;
  background-color: #ff2d2e;
  line-height: 36px;
  font-size: 28px;
  font-weight: 400;
}
.van-dialog {
  width: 90%;
  padding: 0 40px 30px;
  .title {
    color: #242424;
  }
  .van-field {
    font-size: 28px;
  }
}
.popbtn {
  margin-top: 25px;
  div {
    margin-top: 5px !important;
    border-top: none !important;
    width: 260px;
    text-align: center;
    border-radius: 50px;
  }
}
.title {
  font-size: 32px;
  text-align: center;
  margin: 25px 0;
  color: #242424;
}
.break {
  padding: 4px 0;
  color: #242424;
}
.col999 {
  padding: 4px 0;
  color: #999999;
}
.gocer {
  font-size: 32px;
  padding: 14px 0;
  margin-top: 30px;
  color: #fff;
  text-align: center;
}
.centerbox_dialog {
  text-align: left;
  // padding: 0 10px;
  font-size: 28px;
}
.closebtn {
  background-color: #d1d1d1;
}
.confirmbtn {
  background-color: #ff2d2e;
}
.breakexplain {
  color: #999;
  font-size: 28px;
  margin: 40px 0;
  p {
    margin-bottom: 10px;
  }
}
.border {
  padding-bottom: 26px;
  border-bottom: 1px solid #e9e9e9;
}
.fontSize {
  font-size: 28px;
  color: #242424;
}
.confirm {
  text-align: center;
  height: 40px;
  color: #ff2d2e;
  margin-top: 40px;
  font-size: 28px;
  line-height: 40px;
}
.title {
  font-size: 36px;
  text-align: center;
  margin: 25px 0;
  color: #242424;
}
.ceringModel {
  width: 74%;
  .centerbox_dialog {
    padding: 0 0 30px !important;
    border-bottom: 1px solid #e9e9e9;
  }
  .confirm {
    margin: 30px 0 0;
  }
}
.centerbox_dialog {
  text-align: left;
  // padding: 0 50px;
  color: #666666;
  // padding: 0 10px;
  font-size: 30px;
}
.JSFontSize {
  font-size: 32px;
  font-weight: 500;
}
.boxBtn {
  width: 100%;
  height: 84px;
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
