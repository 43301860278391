var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mortgage" },
    [
      _c("van-nav-bar", {
        attrs: {
          "left-arrow": "",
          fixed: "",
          "safe-area-inset-top": "",
          placeholder: "",
          title: "存料"
        },
        on: { "click-left": _vm.leftClick }
      }),
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          _c("div", { staticClass: "box" }, [
            _c("div", { staticClass: "flex_between  border" }, [
              _c("div", { staticClass: "explain" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.goodsType === "baiyin"
                        ? "白银"
                        : _vm.goodsType === "bojin"
                        ? "铂金"
                        : _vm.goodsType === "bajin"
                        ? "钯金"
                        : "黄金"
                    ) +
                    "现价(元/克) "
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "number",
                  style: {
                    color:
                      _vm.goodsType === "baiyin"
                        ? _vm.agRedColor
                        : _vm.goodsType === "bojin"
                        ? _vm.ptRedColor
                        : _vm.goodsType === "bajin"
                        ? _vm.pdRedColor
                        : _vm.redColor
                        ? "#ff2d2e"
                        : "#02790D"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.goodsType === "baiyin"
                          ? _vm.pricebaiy
                          : _vm.goodsType === "bojin"
                          ? _vm.priceboj
                          : _vm.goodsType === "bajin"
                          ? _vm.pricebaj
                          : _vm.pricehj
                      ) +
                      " "
                  )
                ]
              )
            ]),
            _c("div", { staticClass: "flex_between marginTop" }, [
              _c("div", { staticClass: "explain " }, [
                _vm._v("存料单数量（单）/存料单总重量（克）")
              ]),
              _c("div", { staticClass: "number" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.checkList.length) +
                    "/" +
                    _vm._s(_vm.totalData.orderWeight) +
                    " "
                )
              ])
            ]),
            _c("div", { staticClass: "flex_between" }, [
              _c("div", { staticClass: "explain" }, [
                _vm._v("存料初始均价（元/克）")
              ]),
              _c("div", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.totalData.averagePrice))
              ])
            ]),
            _c("div", { staticClass: "flex_between" }, [
              _c("div", { staticClass: "explain" }, [
                _vm._v("已收总金额（元）")
              ]),
              _c("div", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.totalData.preMoney))
              ])
            ]),
            _c("div", { staticClass: "flex_between" }, [
              _c("div", { staticClass: "explain fontSize" }, [
                _vm._v("总其他费用（元）")
              ]),
              _c("div", { staticClass: "number fontSize" }, [
                _vm._v(_vm._s(_vm.totalData.CustodyFee))
              ])
            ]),
            _c("div", { staticClass: "flex_between" }, [
              _c("div", { staticClass: "explain  fontSize" }, [
                _vm._v("总补仓金额(元)")
              ]),
              _c("div", { staticClass: "number fontSize" }, [
                _vm._v(_vm._s(_vm.totalData.addMoney))
              ])
            ]),
            _c("div", { staticClass: "flex_between" }, [
              _c("div", { staticClass: "explain fontSize" }, [
                _vm._v("总结算尾款（元）")
              ]),
              _c("div", { staticClass: "number fontSize" }, [
                _vm._v(_vm._s(_vm.totalData.statementMoney))
              ])
            ])
          ]),
          _c(
            "van-list",
            {
              attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            },
            _vm._l(_vm.getMortgageList, function(item, index) {
              return _c("div", { key: index, staticClass: "flex" }, [
                _c("div", { staticClass: "line" }),
                _c("div", { staticClass: "orderInfo" }, [
                  _c("div", { staticClass: "flex_between borderBtm" }, [
                    _c("div", { staticClass: "flex" }, [
                      _c("h2", [_vm._v("黄金金条9999")]),
                      _c("div", { staticClass: "icon" }, [_vm._v(" 存料 ")])
                    ]),
                    _c("div", { staticClass: "flex" }, [
                      _c("div", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm._f("formatDate_")(item.time)))
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkList,
                            expression: "checkList"
                          }
                        ],
                        staticClass: "checkBox",
                        attrs: { type: "checkbox", name: "checkBoxTest" },
                        domProps: {
                          value: item,
                          checked: Array.isArray(_vm.checkList)
                            ? _vm._i(_vm.checkList, item) > -1
                            : _vm.checkList
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.checkList,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = item,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.checkList = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.checkList = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.checkList = $$c
                              }
                            },
                            _vm.checkBox
                          ]
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "flex_between marginTop" }, [
                    _c("div", { staticClass: "explain" }, [
                      _vm._v("存料重量(克)/初始单价(元/克)")
                    ]),
                    _c("div", { staticClass: "number" }, [
                      _vm._v(_vm._s(item.weight) + "/" + _vm._s(item.initPrice))
                    ])
                  ]),
                  _c("div", { staticClass: "flex_between marginTop" }, [
                    _c("div", { staticClass: "explain" }, [
                      _vm._v(
                        " 累计仓租 (自" +
                          _vm._s(
                            _vm._f("formatTimeYear")(
                              _vm._f("formatDate_")(item.time)
                            )
                          ) +
                          "起) "
                      )
                    ]),
                    _c("div", { staticClass: "number" }, [
                      _vm._v(_vm._s(item.warehouseMoney))
                    ])
                  ]),
                  _c("div", { staticClass: "flex_between marginTop" }, [
                    _c("div", { staticClass: "explain" }, [
                      _vm._v("快递费（元）")
                    ]),
                    _c("div", { staticClass: "number" }, [
                      _vm._v(_vm._s(item.moneyExpress))
                    ])
                  ]),
                  _c("div", { staticClass: "flex_between marginTop" }, [
                    _c("div", { staticClass: "explain" }, [
                      _vm._v("保险费（元）")
                    ]),
                    _c("div", { staticClass: "number" }, [
                      _vm._v(_vm._s(item.moneyInsurance))
                    ])
                  ]),
                  _c("div", { staticClass: "flex_between marginTop" }, [
                    _c("div", { staticClass: "explain" }, [
                      _vm._v("已收金额（元）")
                    ]),
                    _c("div", { staticClass: "number" }, [
                      _vm._v(_vm._s(item.money))
                    ])
                  ]),
                  _c("div", { staticClass: "flex_between marginTop" }, [
                    _c("div", { staticClass: "explain" }, [
                      _vm._v("补仓金额（元）")
                    ]),
                    _c("div", { staticClass: "number" }, [
                      _vm._v(_vm._s(item.makeUpMoney))
                    ])
                  ]),
                  _c("div", { staticClass: "flex_between marginTop" }, [
                    _c("div", { staticClass: "explain" }, [
                      _vm._v("剩余比例(%)")
                    ]),
                    _c("div", { staticClass: "number" }, [
                      _vm._v(_vm._s(item.currentDepositPercent))
                    ])
                  ])
                ])
              ])
            }),
            0
          )
        ],
        1
      ),
      _c(
        "van-dialog",
        {
          attrs: { "show-confirm-button": false, "get-container": "#app" },
          model: {
            value: _vm.showbreak,
            callback: function($$v) {
              _vm.showbreak = $$v
            },
            expression: "showbreak"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("存料结算")]),
          _c("div", { staticClass: "centerbox_dialog" }, [
            _c("div", { staticClass: "flex_between col999" }, [
              _c("div", [_vm._v("平仓价格 (元)")]),
              _c("div", [_vm._v(_vm._s(_vm.pricehj))])
            ]),
            _c("div", { staticClass: "flex_between col999" }, [
              _c("div", [_vm._v("平仓克重 (克)")]),
              _c("div", [_vm._v(_vm._s(_vm.totalData.orderWeight))])
            ])
          ]),
          _c("div", { staticClass: "flex_between popbtn" }, [
            _c(
              "div",
              { staticClass: "gocer closebtn", on: { click: _vm.close } },
              [_vm._v("取消")]
            ),
            _c(
              "div",
              { staticClass: "gocer confirmbtn", on: { click: _vm.commit } },
              [_vm._v("确定")]
            )
          ])
        ]
      ),
      _c(
        "van-dialog",
        {
          staticClass: "ceringModel",
          attrs: { "show-confirm-button": false, "get-container": "#app" },
          model: {
            value: _vm.ceringModel,
            callback: function($$v) {
              _vm.ceringModel = $$v
            },
            expression: "ceringModel"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("提示")]),
          _c("div", { staticClass: "centerbox_dialog" }, [
            _vm._v(_vm._s(_vm.stopTrading))
          ]),
          _c(
            "div",
            {
              staticClass: "confirm",
              on: {
                click: function($event) {
                  _vm.ceringModel = false
                }
              }
            },
            [_vm._v("我知道了")]
          )
        ]
      ),
      _c("div", { staticClass: "boxBtn", on: { click: _vm.breakjieSuan } }, [
        _c("div", { staticClass: "btn" }, [_vm._v("存料结算")])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }